import { PageSlice } from "lib/ui/page/PageSlice"
import { ReactNode } from "react"
import React from "react"
import styled from "styled-components"
import { VStack } from "lib/ui/Stack"

interface Props {
  hero: ReactNode
  uvp: ReactNode
  cta: ReactNode
}

const Content = styled.div`
  min-height: calc(100vh - 74px);

  display: grid;
  grid-template-columns: 1fr 1fr;

  align-items: center;

  > * {
    :last-child {
      align-self: end;
    }
  }

  gap: 40px;

  @media (max-width: 940px) {
    grid-template-columns: 1fr;

    position: relative;

    > * {
      :last-child {
        z-index: -1;
        position: absolute;
        bottom: 0;
        max-height: 100%;
        right: 0;
        max-width: 480px;
      }
    }
  }

  @media (max-width: 820px) {
    grid-template-columns: 1fr;

    > * {
      :last-child {
        visibility: hidden;
      }
    }
  }

  @media (max-width: 520px) {
    align-items: flex-start;
    max-height: none;

    h1 {
      font-size: 24px;
    }
  }
`

export const PrimarySlice = ({ uvp, hero, cta }: Props) => {
  return (
    <PageSlice>
      <Content>
        <VStack gap={40}>
          {uvp}
          {cta}
        </VStack>
        {hero}
      </Content>
    </PageSlice>
  )
}
