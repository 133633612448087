import { PrimarySlice } from "home/components/PrimarySlice"
import { PageContainer } from "lib/ui/page/PageContainer"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Text } from "lib/ui/Text"
import { VStack } from "lib/ui/Stack"
import { PrimaryButton } from "lib/ui/buttons/rect/PrimaryButton"
import { ExternalLink } from "lib/navigation/Link/ExternalLink"
import { increaserWebsite, youTubeChannel } from "shared/externalResources"
import { OutlinedButton } from "lib/ui/buttons/rect/OutlinedButton"
import styled from "styled-components"
import { Footer } from "shared/components/Footer"

const CTA = styled.div`
  display: grid;
  grid-template-columns: minmax(240px, 1fr);
  gap: 20px;
`

const HomePage = () => {
  return (
    <PageContainer>
      <PrimarySlice
        uvp={
          <Text as="h1">
            <Text as="span" color="attention">
              Helping people get efficient
            </Text>
            <br /> and have more freedom
            <br />{" "}
            <Text as="span" color="supporting">
              with content and tools.
            </Text>
          </Text>
        }
        cta={
          <VStack alignItems="start">
            <CTA>
              <ExternalLink to={increaserWebsite}>
                <PrimaryButton
                  style={{ width: "100%" }}
                  kind="attention"
                  isRounded
                  size="xl"
                >
                  Stop Overworking
                </PrimaryButton>
              </ExternalLink>
              <ExternalLink to={youTubeChannel}>
                <OutlinedButton style={{ width: "100%" }} isRounded size="xl">
                  Remote Lifestyle
                </OutlinedButton>
              </ExternalLink>
            </CTA>
          </VStack>
        }
        hero={<StaticImage alt="Radzion" src="../images/hero.png" />}
      />
      <Footer />
    </PageContainer>
  )
}

export default HomePage
